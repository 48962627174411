// Zoom Instruction
var zoomInstruction = $('.mobile-true .pinchpull');
zoomInstruction.show();

setTimeout(function () {
    zoomInstruction.fadeOut(800);
}, 1500);

$('body').on('touchstart', function () {
    zoomInstruction.hide();
});
