// Menu
function menu() {
  $('.sitenav').stop(true).fadeToggle("slow", function() {});
  setTimeout(function() {
    $('.nav-link').toggleClass('open');
    $('body').toggleClass('menu-open');
  }, 500);
}

$('.nav-link').on('click', function() {
  menu();
});
