// Sliders
let originaltitle = $("footer").find("h1").text();

$(document).ready(function() {
  var owl = $('.owl-carousel');

  // DO NOT move initialized.owl.carousel under the plugin init call (there is a bug - https://github.com/OwlCarousel2/OwlCarousel2/issues/1472)

  owl.on('initialized.owl.carousel', function(property) {

    setTimeout(function() {


      $title = $('.owl-carousel').find(".active").find("figure").attr('data-slide-title');
      $count = $('.owl-carousel').find(".active").find("figure").attr('data-slide-number');
      $totalcount = $('#project').attr('data-slide-count');

      $("#project-section").find("#footer-left").find(".slide-count span.slide-num").text($count)
      $("#project-section").find("#footer-left").find(".slide-count span.slide-total").text($totalcount)


      $("#slides-page").find("#footer-left").find(".slide-count span.slide-num").text($count)
      $("#slides-page").find("#footer-left").find(".slide-count span.slide-total").text($totalcount)
      $("#slides-page").find("footer").find("h1").find("a").text($title);



    }, 200);

  });

  $('.owl-carousel').owlCarousel({
    items: 1,
    margin: 24,
    nav: true,
    dots: false,
    loop: false,
    mouseDrag: false,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    URLhashListener: true,
    startPosition: 'URLHash',
    responsiveClass: true,

    responsive : {

      960 : {
        loop: true,
      }
  }

  })


  owl.on('changed.owl.carousel', function(property) {

    $current = property.item.index;
    $title = $(property.target).find(".owl-item").eq($current).find("figure").attr('data-slide-title');
    $count = $(property.target).find(".owl-item").eq($current).find("figure").attr('data-slide-number');
    $totalcount = $('#project').attr('data-slide-count');

    $("#project-section").find("#footer-left").find(".slide-count span.slide-num").text($count)
    $("#project-section").find("#footer-left").find(".slide-count span.slide-total").text($totalcount)

    $("#slides-page").find("#footer-left").find(".slide-count span.slide-num").text($count)
    $("#slides-page").find("#footer-left").find(".slide-count span.slide-total").text($totalcount)
    $("#slides-page").find("footer").find("h1").find("a").text($title);


  });


  $("#slides-page footer h1.site-title a").hover(

    function() {
      $(this).text("Back");
    },
    function() {
      $(this).text($('.owl-carousel').find(".active").find("figure").attr('data-slide-title'));
    }
  );

  $("#thumbs-page footer h1.site-title a").hover(

    function() {
      $(this).text("Back");
    },
    function() {
      $(this).text(originaltitle);
    }
  );

  $("#thumbs-page footer h1.site-title a").click(function(event) {
    event.preventDefault();
    history.back(-1)

  });


  $("#macro-page footer h1.site-title a").hover(

    function() {
      $(this).text("Back");
    },
    function() {
      $(this).text(originaltitle);
    }

  );



  $(".section-prints footer h1.site-title a").hover(

    function() {
      $(this).text("Back");

    },
    function() {
      $(this).text(originaltitle);

    }

  );


  $(".section-collections footer h1.site-title a").hover(

    function() {
      $(this).text("Back");

    },
    function() {
      $(this).text(originaltitle);
    }

  );



  $("#project-section footer h1.site-title a").hover(

    function() {
      $(this).text("Back");
    },
    function() {
      $(this).text(originaltitle);
    }

  );

  // Keyboard Controls
  $(document).keyup(function(e) {
    if ($('body').hasClass('slides-section')) {
      if (e.key === "ArrowLeft") {
        /*left key*/
        owl.trigger('prev.owl.carousel');
      } else if (e.key === "ArrowRight") {
        /*right key*/
        owl.trigger('next.owl.carousel');
      }
    }

  });


});
