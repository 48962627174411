// ======================================================================
// Levon Biss | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');
import $ from 'jquery';
window.$ = window.jQuery = $;

// ======================================================================
// Plugins
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

// head.js
require('./modules/head.js');

// Owl
require('owl.carousel');

// ======================================================================
// Modules
// ======================================================================

// Transitions
require('./modules/transitions.js');

// Lozad
require('./modules/lozad.js');

// Menu
require('./modules/menu.js');

// Feature Slides
require('./modules/featured-slides.js');

// Feature Slides
require('./modules/exhibit-slides.js');

// Lightboxes
// require('./modules/lightbox.js');

// Zoom Instruction
require('./modules/pinchpull.js');
