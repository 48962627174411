// Exhibit Sliders

$(document).ready(function() {
  var owl = $('.exhibit-gallery');
  owl.owlCarousel({
    items: 1,
    margin: 0,
    nav: true,
    dots: false,
    loop: false,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    stagePadding: 0



  });

});
